import React, {useEffect} from "react";
import {scrollTo} from "utils";
import TopBar4 from "./sections/pc/TopBar4";
import Intro4 from "./sections/pc/Intro4";
import Features4 from "./sections/pc/Features4";
import Features2 from "./sections/pc/Features2";
import Footer2 from "./sections/pc/Footer2";

const Pc = () => {
    useEffect(() => {
        scrollTo("root");
    });

    return (
        <div className="landing">
            <TopBar4/>
            <Intro4/>
            <Features2/>
            <Features4/>
            <Footer2/>
        </div>
    );
};

export default Pc;
