import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import Group from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PieChart from '@material-ui/icons/PieChart';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

const useStyles = makeStyles((theme) => ({
    feature: {
        [theme.breakpoints.down("xs")]: {
            textAlign: "center !important",
        },
    },

    musicImage: {
        width: "362px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    musicImageWrapper: {
        position: "relative",
        textAlign: "right",

        // marginLeft: "100px",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "81%",
            top: "11%",
            left: 0,
            right: 0,
            margin: "0 -2000px 0 -80px",
            borderRadius: "300px",
            background: theme.palette.primary.main,
            zIndex: -1,
            opacity: 0.1,

            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "calc(10% + 20px)",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("sm")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },
    },
}));

const Feature4 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="feature4">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="center">
                    <Grid item lg={8} md={6} sm={6} xs={12}>
                        <div className="max-w-450 mb-16">
                            <h1 className="mt-0 font-normal text-40">App Key Features</h1>
                            <p>
                                The app is built with tools for mental growth and social media capabilities with a
                                purpose to build a global pigeoncircle community.
                            </p>
                        </div>


                        <Grid container spacing={isMobile ? 5 : 8}>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Group color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Online Participants Enroll</h4>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <PieChart color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Association Payment Tracking
                                    </h4>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentIcon color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Prepare Fixtures
                                    </h4>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssessmentIcon color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Live Leaderboard
                                    </h4>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <HourglassEmpty color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Share Live Competition Status
                                    </h4>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Assign Umpires and Track Competition

                                    </h4>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12} className={classes.feature}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/pc/dashboard.png"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Feature4;
