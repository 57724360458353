import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button, Icon} from "@material-ui/core";
import AppleIcon from "../../common/icons/AppleIcon";


import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CardMedia from "@material-ui/core/CardMedia";
import ReactPlayer from "react-player";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const useStyles = makeStyles((theme) => ({
    themeText: {
        color: theme.palette.text.primary,
    },

    musicImage: {
        width: "362px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    video: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    modal: {
        zIndex: "999999",
        padding: "80px"
    },
    musicImageWrapper: {
        position: "relative",
        display: "inline-block",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "80%",
            left: 0,
            right: 0,
            margin: "0 180px -50px -2000px",
            bottom: 0,
            borderRadius: "300px",
            background: theme.palette.primary.main,
            transformOrigin: "right bottom",
            transform: "rotate(45deg)",
            zIndex: -1,
            opacity: 0.1,
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "10%",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("sm")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },
    },
}));

const Intro4 = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <section className="section section-intro" id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/pc/dashboard.png"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div>
                            <p className="text-primary m-0 mb-4 text-20">
                                <img
                                    style={{borderRadius: "13px"}}
                                    width="50px"
                                    height="50px"
                                    src="/assets/images/pc/pc_logo.png"
                                    alt="app-icon"
                                />
                                <span className="mt-2 ml-3" style={{position: "absolute"}}>Pigeon circle App</span>
                            </p>
                            <p className="max-w-500 mb-8">
                                Pigeon Circle, the first of its kind online tool which has been developed to support
                                associations to conduct and organize their tournaments completely online. System address
                                all the issues facing in manual tournament management such as payment tracking,
                                preparation of fixtures, leaderboard, daily result update etc. System also helps to
                                connect directly with the participants.
                            </p>
                            <div className="mb-4 rounded py-3 mt-15 text-18">Download now 👇🏼</div>
                            <div className="mt-5 flex flex-wrap">
                                <Button
                                    className="mr-6 px-6 mb-4 rounded py-3"
                                    variant="contained"
                                    color="primary"
                                    href={"https://play.google.com"}
                                    target={"_blank"}

                                >
                                    <Icon fontSize="small" className="mr-3">
                                        android
                                    </Icon>
                                    Play Store
                                </Button>
                                <Button
                                    className="px-6 mb-4 rounded py-3"
                                    variant="contained"
                                    color="primary"
                                    href={"https://apps.apple.com"} target={"_blank"}
                                >
                                    <AppleIcon fontSize="small" className="mr-3"/>
                                    App Store
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Intro4;
