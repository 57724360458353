import React from "react";
import {makeStyles, lighten} from "@material-ui/core/styles";
import {Grid, useMediaQuery, Icon, Button, Fab, Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import AppleIcon from "../../common/icons/TwitterIcon";
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import SportsSoccer from '@material-ui/icons/SportsSoccer';
import SportsKabaddi from '@material-ui/icons/SportsKabaddi';
import SportsBasketball from '@material-ui/icons/SportsBasketball';
import Pool from '@material-ui/icons/Pool';
import SportsCricket from '@material-ui/icons/SportsCricket';
import SportsHockey from '@material-ui/icons/SportsHockey';

const useStyles = makeStyles(({palette, ...theme}) => ({
    wrapper: {
        position: "relative",
        "&::after": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            left: "calc(-100vw + 50% - 300px)",
            background: palette.primary.main,
            opacity: 0.1,
            borderTopRightRadius: 300,
            borderBottomRightRadius: 300,
            zIndex: -1,
        },
        "&::before": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            right: "calc(-100vw + 50% - 300px)",
            background: palette.primary.main,
            opacity: 0.1,
            borderTopLeftRadius: 300,
            borderBottomLeftRadius: 300,
            zIndex: -1,
        },
    },
}));

const Features2 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="Features2">
            <div className="container">
                <div className={clsx("text-center mx-auto", classes.wrapper)}>
                    <h1 className="mt-0 mb-7 font-normal text-40">HOW IT WORKS :</h1>
                    <p className="max-w-400 mx-auto mb-8">
                        Easy and effective tournament management tool for associations
                    </p>

                </div>
            </div>
        </section>
    );
};

export default Features2;
