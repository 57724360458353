import React from "react";
import TopBar from "./sections/pc/TopBar";
import PrivacyPolicy from "./sections/pc/PrivacyPolicyWeb";
import Footer2 from "./sections/pc/Footer2";
import {isMobile} from "react-device-detect";
import {useMediaQuery} from "@material-ui/core";
import TopBarMobile from "./sections/pc/TopBarMobile";

const PolicyWeb = () => {

    const mobile = useMediaQuery("(max-width: 768px");
    return (
        <div className="landing">
            {isMobile || mobile ? <TopBarMobile/> : <TopBar/>}
            <PrivacyPolicy/>
            <Footer2/>
        </div>
    );
};

export default PolicyWeb;
